import React from "react";
import Layout from "../components/layout";
import { graphql } from "gatsby";
import styled from "styled-components";
import { GatsbyImage } from "gatsby-plugin-image";

const Donate = styled.div`
  display: flex;
  width: 100%;
  position: relative;
  overflow-x: hidden;
  p {
    margin: 0.5em 0;
  }
`;

const DonateButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  color: #000;
  padding: 0.5em 1em;
  border-radius: 5px;
  margin: 1.5em 0;
  cursor: pointer;
  width: 250px;
  transition: all 0.3s ease;
  &:hover {
    background-color: #ccc;
    color: #fff;
    a {
      font-family: ABC Favorite Lining, serif;
      transition: 0.3s;
    }
  }
  a {
    color: #000;
    text-decoration: none;
  }
`;

const Edition = styled.div`
  display: flex;
  flex-direction: column;
  margin: 4em 0 1em;
`;
const Names = styled.div`
  display: flex;
  flex-direction: column;
  margin: 1em 0;
`;

const EditionImage = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: left;
  align-items: left;
  margin: 0.5em 0;
  height: 300px;
  width: auto;
  img {
    object-fit: contain;
  }
`;

import { PageProps } from "gatsby";

interface SupportProps extends PageProps {
  data: {
    allWpPage: {
      edges: {
        node: {
          id: string;
          title: string;
          support: {
            content: string;
            text: string;
            names: string;
            edition: {
              localFile: {
                childImageSharp: {
                  gatsbyImageData: any;
                };
              };
            };
          };
        };
      }[];
    };
  };
}

const Support = ({ data }: SupportProps) => {
  const posts = data.allWpPage.edges;
  return (
    <div className="container">
      <Layout>
        <div>
          {posts.map((post) => {
            return (
              <div key={post.node.id}>
                <div className="text">
                  <h1>{post.node.title}</h1>
                  {post.node.support.content ? (
                    <div
                      dangerouslySetInnerHTML={{
                        __html: post.node.support.content,
                      }}
                    />
                  ) : null}
                </div>
                <div className="donate">
                  <div>
                    <DonateButton>
                      <a
                        href="https://for.zahls.ch/ch-DE/vpos?amount=50&currency=CHF"
                        target="_blank"
                        rel="noreferrer"
                      >
                        Donate 50.- CHF
                      </a>
                    </DonateButton>
                    <DonateButton>
                      <a
                        href="https://for.zahls.ch/ch-DE/vpos?amount=100&currency=CHF"
                        target="_blank"
                        rel="noreferrer"
                      >
                        Donate 100.- CHF
                      </a>
                    </DonateButton>
                    <DonateButton>
                      <a
                        href="https://for.zahls.ch/ch-DE/vpos?amount=500&currency=CHF"
                        target="_blank"
                        rel="noreferrer"
                      >
                        Donate 500.- CHF
                      </a>
                    </DonateButton>
                    <DonateButton>
                      <a
                        href="https://for.zahls.ch/ch-DE/vpos?amount=1000&currency=CHF"
                        target="_blank"
                        rel="noreferrer"
                      >
                        Donate 1000.- CHF
                      </a>
                    </DonateButton>
                    <DonateButton>
                      <a
                        href="https://for.zahls.ch/ch-DE/vpos?amount=&currency=CHF"
                        target="_blank"
                        rel="noreferrer"
                      >
                        Donate more than 1000.- CHF
                      </a>
                    </DonateButton>
                  </div>
                  <Edition>
                    {post.node.support.text ? (
                      <div
                        dangerouslySetInnerHTML={{
                          __html: post.node.support.text,
                        }}
                      />
                    ) : null}
                    <EditionImage>
                    {post.node.support.edition ? (
                      <GatsbyImage
                        image={
                          post.node.support.edition.localFile.childImageSharp
                            .gatsbyImageData
                        }
                        alt="edition"
                        objectFit="contain"
                        objectPosition="left"
                        style={{ height: "300px" }}
                      />
                    ) : null}
                    </EditionImage>
                    <Names>
                    {post.node.support.names ? (
                      <div
                        dangerouslySetInnerHTML={{
                          __html: post.node.support.names,
                        }}
                      />
                    ) : null}
                    </Names>
                  </Edition>
                </div>
              </div>
            );
          })}
        </div>
      </Layout>
    </div>
  );
};

export const Head = () => (
  <>
    <title>For – About</title>
    <meta
      name="description"
      content="For ist ein nicht gewinnorientierter Ausstellungsraum in Basel (CH), der zeitgenössische Kunst zeigt. Der Fokus liegt auf Arbeiten, die sich gemeinsam mit dem Publikum kritisch-imaginativ mit populären Phänomenen unserer Gegenwart auseinandersetzen. Ergänzend zu den Ausstellungen erscheint ein kulturwissenschaftliches Magazin."
    />
  </>
);
export default Support;
export const pageQuery = graphql`
  query {
    allWpPage(filter: { title: { eq: "Support" } }) {
      edges {
        node {
          id
          title
          slug
          support {
            content
            text
            edition {
              localFile {
                childImageSharp {
                   gatsbyImageData(layout: FULL_WIDTH, quality: 100)
                }
              }
            }
            names
          }
        }
      }
    }
  }
`;
